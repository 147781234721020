import React from "react"
import { ThemeProvider } from "styled-components"
import Sticky from "react-stickynode"
import { theme } from "../theme"
import { GlobalStyle, ContentWrapperNoBg } from "../containers/style"
import { ResetCSS } from "../assets/css/style"
import Navbar from "../containers/Global/Navbar"
import FooterSection from "../containers/Global/Footer"
import Policies from "../containers/Global/Policies/index1"
import Cookies from "../containers/Landing/Cookies"

import { DrawerProvider } from "../contexts/DrawerContext"
import { ParallaxProvider } from "react-scroll-parallax"
import SEO from "../components/seo"

export default () => {
    return (
        <ThemeProvider theme={theme}>
            <ParallaxProvider>
                <SEO title="Agrabiz" />

                <ResetCSS />
                <GlobalStyle />
                <Cookies />
                <ContentWrapperNoBg>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar />
                        </DrawerProvider>
                    </Sticky>
                    <Policies />
                    <FooterSection />
                </ContentWrapperNoBg>
            </ParallaxProvider>
        </ThemeProvider>
    )
}
